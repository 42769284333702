<template>
  <v-main class="grey lighten-2">
    <v-container class="fill-height">
      <v-row class="d-flex align-center justify-center">
        <v-col cols="12" md="4">
          <v-img
            class="mx-auto my-2"
            alt="Cowoj Logo"
            contain
            src="../assets/logo.svg"
            transition="scale-transition"
            width="200"
          />
          <v-card class="py-4 px-5">
            <v-form>
            <v-text-field
              ref="email"
              v-model="user.email"
              :rules="[rules.required, rules.email]"
              label="E-mail"
              required
            ></v-text-field>
            <v-text-field
              ref="password"
              v-model="user.password"
              :rules="[rules.required]"
              label="Password"
              type="password"
              required
            ></v-text-field>
            <v-btn
              class="mt-4"
              color="grey lighten-4"
              @click="submit"
              block
            >Login</v-btn>
            </v-form>
            <v-card-actions>
              <dialog-reset-pass />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from 'axios'
import dialogResetPass from '../components/dialogResetPass.vue'

export default {
  name: 'Login',

  components: {
    dialogResetPass
  },

  data: () => ({
    user: {
      email: '',
      password: '',
    },
    rules: {
      required: value => !!value || 'Questo campo è obbligatorio.',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'E-mail non valida.'
      },
    },
  }),
  methods: {
    submit(){
      if( this.$refs.email.validate(true) && this.$refs.password.validate(true) ){
        this.encode_sha256(this.user.password).then( (psw) => {
            let user_check = {};
            user_check.email = this.user.email;
            user_check.password = psw;
            axios.post(
              process.env.VUE_APP_WS+'check_profilo.php',
              {
                json_profilo: JSON.stringify(user_check)
              },
              { auth: { username: process.env.VUE_APP_WS_USERNAME, password: process.env.VUE_APP_WS_PASSWORD } }
            ).then( response => {
              let res = response.data;
              if( !res.abilitato ){
                alert(res.msg);
              } else {
                this.user.password = psw;
                localStorage.setItem('user', JSON.stringify(this.user));
                this.$router.push({ name: 'Home'});
              } 
            }).catch( error => {
              console.log('error', error);
            })
        })
      }
    },
    encode_sha256(string) {
      const utf8 = new TextEncoder().encode(string);
      return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
          const hashArray = Array.from(new Uint8Array(hashBuffer));
          const hashHex = hashArray
          .map((bytes) => bytes.toString(16).padStart(2, '0'))
          .join('');
          return hashHex;
      });
    }
  }
};
</script>
