import Vue from 'vue'
import VueRouter from 'vue-router'

import store from './store'

import Login from '../pages/Login.vue'
import Home from '../pages/Home.vue'

import { publicPath } from '../../vue.config'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: publicPath,
  routes
})

router.beforeEach(function (to, from, next) {
  if( localStorage.getItem('user') == null ){
    if( to.path !== '/login'){
      next({ path: '/login' })
    }else{
      next();
    }
  }else{
    const user = JSON.parse(localStorage.getItem('user'));
    // XXX - aggiungere controllo su webservice per controllare che credenziali salvate nello storage siano ancora valide
    // per ora il controllo guarda solo che i campi siano compilati
    if(user.email !== '' && user.password !== ''){
      store.dispatch('setUserEmail', user.email);
      store.dispatch('setUserPsw', user.password);
      store.dispatch('setUserAuth', true);
      if( to.path == '/login'){
        next({ path: '/' })
      }else{
        next()
      }
    }else{
      next({ path: '/login' })
    }
  }
})

export default router
