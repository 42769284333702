<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            class="mt-4"
            block
            text
            color="grey lighten-1"
            v-bind="attrs"
            v-on="on"
        >
        Password dimenticata?
        </v-btn>
      </template>
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Email*"
                  ref="email"
                  v-model="email"
                  :rules="[rules.required, rules.email]"
                ></v-text-field>
                <small>Inserisci la tua email per resettare la password.</small>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-space-between">
          <v-btn
            color="grey darken-1"
            text
            @click="dialog = false"
          >
            Annulla
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="inviaLink"
          >
            Conferma
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import axios from 'axios'

  export default {
    data: () => ({
        email: '',
        dialog: false,
        rules: {
            required: value => !!value || 'Questo campo è obbligatorio.',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'E-mail non valida.'
            },
        },
    }),
    methods: {
        inviaLink(){
            if( this.$refs.email.validate(true) ){
              axios.post(
                process.env.VUE_APP_WS+'email_reset_password.php',
                { email: this.email },
                { auth: { username: process.env.VUE_APP_WS_USERNAME, password: process.env.VUE_APP_WS_PASSWORD } }
              ).then( response => {
                let res = response.data;
                alert(res.msg);
                this.email = '';
                this.dialog = false;
              }).catch( error => {
                console.log('error', error);
              })
            }
        }
    }
  }
</script>
