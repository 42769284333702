import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    user: {
      email: '',
      password: '',
      auth: false
    },
  },
  mutations: {
    SET_USER_EMAIL(state, email){
      state.user.email = email;
    },
    SET_USER_PSW(state, psw){
      state.user.password = psw;
    },
    SET_USER_AUTH(state, auth){
      state.user.auth = auth;
    }
  },
  actions: {
    setUserEmail(context, email){
      context.commit('SET_USER_EMAIL', email);
    },
    setUserPsw(context, psw){
      context.commit('SET_USER_PSW', psw);
    },
    setUserAuth(context, auth){
      context.commit('SET_USER_AUTH', auth);
    }
  },
  getters: {
    getJsonUser(state){
      return JSON.stringify(state.user)
    }
  }
})

export default store