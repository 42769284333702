<template>
<div>
    <v-app-bar
      app
      color="grey"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Cowoj Logo"
          class="shrink mr-2"
          contain
          src="../assets/logo.svg"
          transition="scale-transition"
          width="140"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        text
        @click="logout"
      >
        <span class="mr-2">Logout</span>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <sceltaServizio @cambiaServizio="cambiaServizio"  />
      <div v-if="servizio.id_servizi != 0">
        <Calendar
          :servizio="servizio"
        />
      </div>
    </v-main>
</div>
</template>

<script>
import sceltaServizio from '../components/sceltaServizio.vue';
import Calendar from '../components/Calendar';

export default {
  name: 'Home',

  components: {
    sceltaServizio,
    Calendar
  },
  data: () => ({
    servizio: {
      id_servizi: 0
    }
  }),
  methods: {
    cambiaServizio(servizio_attivo){
      this.servizio = servizio_attivo;
    },
    logout(){
      localStorage.removeItem('user');
      this.$router.push({ name: 'Login'});
    }
  }
};
</script>
