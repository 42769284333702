<template>
  <v-container>
    <v-row
      v-if="this.id_servizio==1"
      justify="center"
    >
      <v-col cols="12" md="4" lg="6">
        <v-card
          class="mx-auto"
          tile
        >
          <v-card-subtitle class="font-weight-bold" v-if="!this.pacchetto_attivo">
            Non hai un pacchetto disponibile<br><a target="_blank" href="https://cowoj.it/?#prezzi">Richiedi un nuovo pacchetto!</a>
          </v-card-subtitle>
          <v-card-subtitle v-else>
            <b>Pacchetto attivo</b><br>
            Giornate disponibili: {{this.num_giorni_pacchetto_attivo}}<br>Scadenza pacchetto: {{formattedDate(this.data_scadenza_pacchetto)}}</v-card-subtitle>
          <div v-if="selectedDays.length > 0 && this.pacchetto_attivo">
            <v-card-subtitle class="pb-0">Stai prenotando  per le seguenti date:</v-card-subtitle>
            <div class="d-flex flex-wrap mx-2">
              <v-chip v-for="(day, i) in selectedDays" :key="i"
                class="ma-1"
                filter
                small
                v-text="formattedDate(day)"
              ></v-chip>
            </div>
            <v-card-subtitle class="font-weight-bold" v-if="selectedDays.length > this.num_giorni_pacchetto_attivo">
              Non hai più giornate disponibili<br><a target="_blank" href="https://cowoj.it/?#prezzi">Richiedi un nuovo pacchetto!</a>
            </v-card-subtitle>
            <v-btn tile color="success" class="ms-4 mb-4" @click="prenota" :disabled="(selectedDays.length > this.num_giorni_pacchetto_attivo)">
              PRENOTA
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="4" lg="6">
        <v-alert
          transition="slide-y-transition"
          :value="alertVisible"
          dense
          type="error"
        >
          {{alertTxt}}
        </v-alert>
        <v-expansion-panels
          class="mb-3"
        >
          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate>
              Info
              <template v-slot:actions>
                <v-icon color="info">
                  mdi-information-outline
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="type=='month'">
              Seleziona le date che ti interessano e poi premi "PRENOTA" per prenotare
            </v-expansion-panel-content>
            <v-expansion-panel-content v-if="type=='month'">
              <v-list three-line>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>✅ Prenotato</v-list-item-title>
                    <v-list-item-subtitle>Indica i giorni in cui hai già una prenotazione</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      📌 Selezionato
                    </v-list-item-title>
                    <v-list-item-subtitle>Indica i giorni selezionati che si stanno prenotando</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title><v-icon class="legend-pieno">mdi-square-rounded</v-icon> Occupata</v-list-item-title>
                    <v-list-item-subtitle>Indica i giorni in cui non ci sono più posti disponibili</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-if="type=='week'">
              Seleziona il giorno, compila gli orari e poi premi "PRENOTA" per prenotare la sala riunione
            </v-expansion-panel-content>
            <v-expansion-panel-content v-if="type=='week'">
              <v-list three-line>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title><v-icon class="legend-prenotato">mdi-square-rounded</v-icon> Prenotata</v-list-item-title>
                    <v-list-item-subtitle>Indica gli orari in cui hai già una prenotazione</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title><v-icon class="legend-pieno">mdi-square-rounded</v-icon> Occupata</v-list-item-title>
                    <v-list-item-subtitle>Indica gli orari in cui è già presente una prenotazione</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-card
          class="mx-auto"
          tile
        >
        <v-sheet
        tile
        class="d-flex justify-space-between"
        >
          <v-btn
            icon
            class="ma-2"
            @click="$refs.calendario.prev()"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <span class="d-flex align-self-center">{{capFirstLetter(month_name)}}</span>
          <v-btn
            icon
            class="ma-2"
            @click="$refs.calendario.next()"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-sheet>
        <v-sheet tile>
            <v-calendar
              ref="calendario"
              locale="it"
              v-model="startDay"
              @click:date="selectDay"
              @click:event="clickEvent"
              @click:time="clickTime"
              @change="changePage"
              :events="events"
              :type="type"
              :key="calendarKey"
              :first-interval=9
              :interval-count=9
            ></v-calendar>
        </v-sheet>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="timeDialog"
      width="500"
    >
      <v-card>
        <v-card-title>
          Prenota per {{dateTp_str}}
        </v-card-title>
        <v-card-subtitle>
          Seleziona un range di orario<br>(click per modificare)
        </v-card-subtitle>
        <v-card-text>
          <v-row justify="space-around">
          <v-chip
            class="my-2 px-4"
            label
            large
            @click="loadStartTimepicker"
          >
            <v-icon left>
              mdi-clock-start
            </v-icon>
            {{startTime}}
          </v-chip>
          <v-chip
            class="my-2 px-4"
            label
            large
            @click="loadEndTimepicker"
          >
            <v-icon left>
              mdi-clock-end
            </v-icon>
            {{(endTime=="")?'-- : --':endTime}}
          </v-chip>
          </v-row>
          <v-row justify="center">
            <v-time-picker
              v-if="startTpVisible"
              v-model="startTime"
              :key="startTime"
              format="24hr"
              min="9:00"
              :max="(endTime=='')?'18:00':endTime"
              :allowed-minutes="allowedMinutes"
            ></v-time-picker>
            <v-time-picker
              v-if="endTpVisible"
              v-model="endTime"
              :key="endTime"
              format="24hr"
              :min="startTime"
              max="18:00"
              :allowed-minutes="allowedMinutes"
            ></v-time-picker>
          </v-row>
        </v-card-text>


        <v-card-actions class="justify-space-between">
          <v-btn
            @click="timeDialog = false"
          >
            Annulla
          </v-btn>
          <v-btn
            color="success"
            @click="checkTimes(); timeDialog = false"
            :disabled="(endTime!='')?false:true"
          >
            Conferma
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios';
  export default {
    name: 'Calendar',
    props: ['servizio'],
    data: () => ({
      startDay: "",
      month_name: "",
      alertTxt: "",
      alertVisible: false,
      alertTimeOut: false,
      selectedDays: [],
      availableDays: [],
      giorniOltreScadenzaPacchetto: [],
      yourDays: [],
      events: [],
      // variabili per timepicker - start
      dayTime: "",
      startTime: "",
      endTime: "",
      startTpVisible: true,
      endTpVisible: false,
      dateTp: "",
      dateTp_str: "",
      // variabili per timepicker - end
      type: 'month',
      calendarKey: 1,
      timeDialog: false,
      max_numero_giorni_cancellazione_permessa: 2,
      num_giorni_pacchetto_attivo: 0,
      pacchetto_attivo: false,
      id_pacchetto: 0,
      data_scadenza_pacchetto: ""
    }),
    watch: {

      servizio: {
        immediate: true,
        handler(s){
          let t = s.tipo;
          this.id_servizio = s.id_servizi;
          if (t == 'd') {
            this.type = "month";
          }else if (t == 'h') {
            this.type = "week";
          }
          this.getInfoPacchetto()
          this.clearDays();
          this.calendarKey++
        }
      },
      timeDialog: function(newValue){
        if(!newValue){
          // chiusura modal prenotazione
          this.startTime = '';
          this.endTime = '';
        }
      }
    },
    methods: {
      clearDays(){
        this.selectedDays = [];
        this.availableDays = [];
        this.giorniOltreScadenzaPacchetto = [];
        this.yourDays = [];
        this.events = [];
      },
      getInfoPacchetto(){
        axios.post(
          process.env.VUE_APP_WS+'get_info_pacchetto_attivo.php',
          {
            json_profilo: this.$store.getters.getJsonUser, 
            id_servizio: this.id_servizio
          },
          { auth: { username: process.env.VUE_APP_WS_USERNAME, password: process.env.VUE_APP_WS_PASSWORD } }
        ).then( response => {
          let res = response.data;
          if(!res.error){
            this.id_pacchetto = res.id_pacchetto
            this.pacchetto_attivo = res.pacchetto_attivo
            this.num_giorni_pacchetto_attivo = res.num_giorni_pacchetto_attivo
            this.data_scadenza_pacchetto = res.data_scadenza_pacchetto
          }
        })
      },
      prenota(){
        let a_date = [];
        if (this.id_servizio == 1) { // postazione
          a_date = this.selectedDays;
        } else if (this.id_servizio == 2) { // sala riunioni
          a_date[0] = this.dateTp+"|"+this.startTime+":00|"+this.endTime+":00";
        } else {
          alert("Impossibile prenotare. Servizio non riconosciuto.");
          return false;
        }
        axios.post(
          process.env.VUE_APP_WS+'insert_prenotazione.php',
          {
            json_profilo: this.$store.getters.getJsonUser, 
            id_servizio: this.id_servizio,
            id_pacchetto: this.id_pacchetto,
            date: a_date
          },
          { auth: { username: process.env.VUE_APP_WS_USERNAME, password: process.env.VUE_APP_WS_PASSWORD } }
        ).then( response => {
          let res = response.data;
          alert(res.msg);
          if(!res.error){
            this.selectedDays = [];
            this.calendarKey++;
            this.getInfoPacchetto()
          }
        }).catch( error => {
          console.log('error', error);
        })
      },
      changePage({ start }){
        this.events = [];
        this.aggiornaMese(start.month, start.year);
        // prendo l'inizio della settimana
        let monday = null;
        if (this.id_servizio == 2) {
          let d = new Date(start.date); // aggiungo un giorno perchè il calendario inizia di domenica
          d.setDate(d.getDate() + 1);
          monday = this.getStartOfWeek(d);
          this.loadTimes(start.month, start.year, monday);
        }
        this.loadDays(start.month, start.year, monday);
      },
      loadTimes(month, year, monday){
        // aggiungere chiamata per ottenere le prenotazioni già presenti per la settimana
        // passerò il giorno di inizio settimana
        axios.post(
          process.env.VUE_APP_WS+'get_prenotazioni.php',
          {json_profilo: this.$store.getters.getJsonUser, anno:year, mese:month, monday:monday, id_servizio:2},
          { auth: { username: process.env.VUE_APP_WS_USERNAME, password: process.env.VUE_APP_WS_PASSWORD } }
        ).then( response => {
          let res = response.data;
          for(let i=0; i < res.length; i++){
            this.events.push({
              name: (this.$store.state.user.email == res[i].email)?res[i].utente:"Occupata",
              start: res[i].da_ora,
              end: res[i].a_ora,
              color: (this.$store.state.user.email == res[i].email)?"prenotato":"pieno",
              timed: 1
            })
          }
        })
      },
      aggiornaMese(month, year){
        let d = new Date(year, month-1, 1);
        this.month_name = d.toLocaleString('default', { month: 'long' }) + " " + year;
      },
      loadDays(month, year, monday){
        axios.post(
          process.env.VUE_APP_WS+'get_disponibilita.php',
          {json_profilo: this.$store.getters.getJsonUser, anno:year, mese:month, monday:monday, id_servizio:1},
          { auth: { username: process.env.VUE_APP_WS_USERNAME, password: process.env.VUE_APP_WS_PASSWORD } }
        ).then( response => {
          let res = response.data;
          for(let i=0; i < res.length; i++){
            if( new Date(res[i].data) > new Date(this.data_scadenza_pacchetto) ){
              this.giorniOltreScadenzaPacchetto.push(res[i].data)
            }
            if(res[i].liberi > 0 && res[i].prenotato == 0){
              this.availableDays.push(res[i].data);
            }

            if( res[i].liberi > 0  && res[i].aperto == 1){
              this.events.push({
                start: res[i].data,
                timed: 0,
                color: "libero",
                name: res[i].liberi + " desk"
              }); 
            }else{
              if( res[i].aperto == 1){
                this.events.push({
                  start: res[i].data,
                  timed: 0,
                  color: "pieno",
                });
              }
            }
            if(res[i].prenotato == 1){
              this.yourDays.push(res[i].data);
              this.events.push({
                name: "✅",
                start: res[i].data,
                timed: 0,
                color: "prenotato-1"
              });
            }
          }
          for(let i=0; i < this.selectedDays.length; i++){
            this.events.push({
              name: '📌',
              start: this.selectedDays[i],
              timed: 0,
              color: "da_prenotare"
            });
          }
        }).catch( error => {
          console.log('error', error);
        })
      },
      selectDay({ date, weekday, past, present }, event) {
        if (this.type == "week") {
          return false;
        }
        let el_clicked;
        if(event.target.tagName == "SPAN"){
          el_clicked = event.target.parentElement;
        }else{
          el_clicked = event.target;
        }
        let disable_click = false;
        let msg = "";
        if( el_clicked.closest(".v-outside") != null ){ // se è un giorno di un altro mese
          disable_click = true;
        }else if( this.weekdayIsWeekend(weekday) ){
          msg = "Non puoi prenotare nei weekend";
        }else if( past ){
          msg = "Non puoi prenotare nel passato";
        }else if( present ){
          msg = "Non puoi prenotare per oggi";
        }else if( this.yourDays.includes(date) ){
          msg = "Hai già una prenotazione per questo giorno";
        }else if( !this.availableDays.includes(date) ){
          msg = "Postazioni terminate";
        }else if( this.giorniOltreScadenzaPacchetto.includes(date) ){
          msg = "Non hai un pacchetto attivo per queste date"
        }
        if( msg != ''){
          this.alertTxt = msg;
          this.alertVisible = true;
          disable_click = true;
        }

        if(disable_click){
          setTimeout(()=>{
            this.alertVisible=false
          },1500);
          return false;
        }

        let presente = false;
        this.selectedDays.forEach((item, index) => {
          if( item == date ){
            presente = true;
            this.selectedDays.splice(index, 1);
            this.events = this.events.filter(obj => {
              return obj.start !== date || obj.color == "libero"
            })
          }
        })

        if( !presente ){
          this.selectedDays.push(date);
          this.events.push({
            name: '📌',
            start: date,
            timed: 0,
            color: "da_prenotare"
          });
        }

        // riordino i giorni selezionati in base alla data
        this.selectedDays.sort(function(a,b){ return new Date(a) - new Date(b); });
      },
      loadStartTimepicker() {
        this.startTpVisible = true;
        this.endTpVisible = false;
      },
      loadEndTimepicker() {
        this.endTpVisible = true;
        this.startTpVisible = false;
      },
      clickEvent(e) {
        if( e.event.color == "prenotato" || e.event.color == "prenotato-1" ){
          let daydate;
          if (e.event.timed == 1) {
            let start = e.event.start.split(' ');
            let end = e.event.end.split(' ');
            daydate = e.day.date+"|"+start[1]+"|"+end[1];
          } else {
            daydate = e.day.date;
          }
          let today = new Date();            
          if( this.diff_in_days(this.getStringDate(today), e.day.date) > this.max_numero_giorni_cancellazione_permessa ){
            if( confirm("Desideri disdire la prenotazione per il giorno "+this.formattedDate(e.day.date)+"?") ){
              axios.post(
                process.env.VUE_APP_WS+'remove_prenotazione.php',
                {
                  json_profilo: this.$store.getters.getJsonUser, 
                  id_servizio: this.id_servizio,
                  id_pacchetto: this.id_pacchetto,
                  date: daydate
                },
                { auth: { username: process.env.VUE_APP_WS_USERNAME, password: process.env.VUE_APP_WS_PASSWORD } }
              ).then( response => {
                let res = response.data;
                alert(res.msg);
                if(!res.error){
                  if(this.id_servizio == 1){
                    this.yourDays = this.yourDays.filter(el => {
                      return el !== daydate
                    })
                  }
                  this.getInfoPacchetto()
                  this.calendarKey++;
                }
              })
            }
          }else{
            alert("Non è possibile cancellare questa prenotazione.");
          }
        }
        e.nativeEvent.stopPropagation();
      },
      clickTime (s) {
        let disable_click = false;
        let msg = "";
        if( this.weekdayIsWeekend(s.weekday) ){
          msg = "Non puoi prenotare nei weekend";
        }else if( this.isToday(s.date) ){
          msg = "Non puoi prenotare per oggi";
        }else if( s.past ){
          msg = "Non puoi prenotare nel passato";
        }else if( !this.isFree(this.toTime(s))){
          msg = "Sala riunioni già occupata in quest'orario";
        }
        if( msg != ''){
          this.alertTxt = msg;
          this.alertVisible = true;
          disable_click = true;
        }

        if(disable_click){
          clearTimeout(this.alertTimeOut);
          this.alertTimeOut = setTimeout(()=>{
            this.alertVisible=false
          },1500);
          return false;
        }


        this.dateTp = s.date;
        this.dateTp_str = this.formattedDate(s.date);
        // prendo il ts del click del mouse e lo arrotondo a step di 15sec (9:00/9:15/9:30/9:45) 
        let ts = this.toTime(s);
        ts = this.roundTime(ts);
        this.dayTime = new Date(ts);
        this.startTime =  this.dayTime.getHours() + ':' + ( this.dayTime.getMinutes() == 0 ? '00' : this.dayTime.getMinutes() );
        this.loadStartTimepicker();
        this.timeDialog = true;
      },
      checkTimes(){
        let date_start = new Date(this.dateTp+" "+this.startTime+":00");
        let date_end = new Date(this.dateTp+" "+this.endTime+":00");
        let ts_start = date_start.getTime();
        let ts_end = date_end.getTime();
        if( this.isFree(ts_start, ts_end) ){
          this.prenota();
        }else{
          this.alertTxt = "Sala riunioni occupata negli orari selezionati, ricontrolla le disponibilità.";
          this.alertVisible = true;
          clearTimeout(this.alertTimeOut);
          this.alertTimeOut = setTimeout(()=>{
            this.alertVisible=false
          },2000);
        }
      },
      toTime (t) {
        return new Date(t.year, t.month - 1, t.day, t.hour, t.minute).getTime()
      },
      roundTime (time, down = true) {
        const roundTo = 30 // minutes
        const roundDownTime = roundTo * 60 * 1000

        return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime))
      },
      getStringDate(data){
        let dd = String(data.getDate()).padStart(2, '0');
        let mm = String(data.getMonth() + 1).padStart(2, '0');
        let yyyy = data.getFullYear();
        return yyyy + '-' + mm + '-' + dd;
      },
      getStartOfWeek(date){
        let d = new Date(date);
        var day = d.getDay();
        let diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
        return new Date(d.setDate(diff));
      },
      formattedDate(date){
        let d = new Date(date);
        return d.toLocaleDateString("it-IT");
      },
      weekdayIsWeekend(wd){
        return (wd == 0 || wd == 6 ) ? true : false
      },
      isToday(d) {
        const somedate = new Date(d)
        const today = new Date()
        return somedate.getDate() == today.getDate() &&
        somedate.getMonth() == today.getMonth() &&
        somedate.getFullYear() == today.getFullYear()
      },
      isFree(ts_compared_1, ts_compared_2 = 0){
        let isfree = true;
        this.events.forEach( el => {
          let date_start = new Date(el.start);
          let date_end = new Date(el.end);
          let ts_start = date_start.getTime();
          let ts_end = date_end.getTime();
          if( ts_compared_2 == 0 ){
            if( ts_compared_1 >= ts_start && ts_compared_1 <= ts_end ){
              isfree = false;
            }
          } else {
            if(
              ts_compared_1 > ts_start && ts_compared_2 > ts_start && ts_compared_2 < ts_end ||
              ts_compared_1 < ts_start && ts_compared_2 > ts_start && ts_compared_2 < ts_end ||
              ts_compared_1 > ts_start && ts_compared_1 < ts_end && ts_compared_2 > ts_end ||
              ts_compared_1 < ts_start && ts_compared_2 > ts_end              
            ){
              isfree = false;
            }
          }
        }) 
        return isfree;
      },
      capFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      allowedMinutes(m){
        return m % 30 === 0;
      },
      diff_in_days(str_date_1, str_date_2){
        let date1 = new Date(str_date_1);
        let date2 = new Date(str_date_2);
        let diff_in_time = date2.getTime() - date1.getTime();
        return diff_in_time / (1000 * 3600 * 24);
      }
    },
    mounted(){
      this.startDay = new Date();
    }
  }
</script>
<style>
/* .v-event {
  height: 20px !important;
  width: 20px !important;
  position: realtive !important;
  left: 10% !important;
} */
.v-calendar .v-event-timed-container{
  margin-right: 0px !important;
}
.v-event.libero {
  background-color: #333 !important;
  border-color: #333 !important;
}
.v-event-timed.prenotato-1,
.v-event.prenotato-1 {
  background-color: transparent !important;
  border-color: transparent !important;
}
.v-event-timed.prenotato,
.v-event.prenotato {
  background-color: rgb(9, 86, 174)  !important;
  border-color: rgb(9, 86, 174) !important;
}
.v-event.da_prenotare {
  background-color: transparent !important;
  border-color: transparent !important;
}
.v-event-timed.pieno,
.v-event.pieno {
  background-color: rgba(243, 12, 12, 0.8)  !important;
  border-color: rgba(243, 12, 12) !important;
}
.legend-libero{ color: rgb(9, 174, 28) !important; }
.legend-prenotato{ color: rgb(9, 86, 174) !important; }
.legend-da_prenotare{ color: rgb(9, 86, 174, 0.5) !important; }
.legend-pieno{ color: rgba(243, 12, 12, 0.8)  !important; }
.v-calendar-daily__head .v-calendar-daily_head-day-label .v-btn{
  width: auto !important;
  height: auto !important;
}
.v-alert{
  position: fixed !important;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, -50%);
  margin: 0px auto;
  z-index: 999;
  width: 90%;
}
</style>