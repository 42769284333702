<template>
    <v-container>
        <v-row justify="center">
             <v-col
                class="d-flex"
                cols="12" md="4" lg="6"
            >
                <v-select
                :items="items"
                item-text="nome"
                item-value="id_servizi"
                @change="changeServizio"
                label="Quale servizio vuoi prenotare?"
                solo
                ></v-select>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios';

export default {
    name: 'sceltaPostazione',
    data: () => ({
      items: [],
    }),
    methods: {
        changeServizio(id_s){
            var s_attivo = this.items.find( i => {
                return i.id_servizi === id_s
            })
            this.$emit('cambiaServizio', s_attivo);
        }
    },
    mounted(){
        axios.post(process.env.VUE_APP_WS+'get_servizi.php',{ json_profilo: this.$store.getters.getJsonUser },{ auth: { username: process.env.VUE_APP_WS_USERNAME, password: process.env.VUE_APP_WS_PASSWORD } }).then( response => {
            this.items = response.data;
        }).catch( error => {
            console.log('error', error);
        })
    }
}
</script>
<style>

</style>